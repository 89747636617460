import React from 'react'
import BasicCard from '../common/BasicCard/BasicCard'
import GridWrapper from '../common/GridWrapper/GridWrapper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';
import DnsIcon from '@mui/icons-material/Dns';
import myImage from '../images/qd-icon.png';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {DataGrid} from '@mui/x-data-grid';
import EntryIcon from '../images/Entry.svg';
import HandIcon from '../images/Handwritten.svg';
import TableIcon from '../images/Table.svg';
import TamilIcon from '../images/Tamil.svg';
import CensusIcon from '../images/Census.svg';
import DirectoryIcon from '../images/Directory.svg';
import ClassifyIcon from '../images/Classification.svg';
import LeaseIcon from '../images/Lease.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const columns = [
    {field: 'id', headerName: 'ID', flex: 1},
    {
        field: 'model',
        headerName: 'Model',
        flex: 2,
        editable: false,
    },
    {
        field: 'created',
        headerName: 'Created',
        flex: 1,
        editable: false,
    },
    {
        field: 'lastUsed',
        headerName: 'Last Modified',
        sortable: false,
        flex: 1,
        width: 160
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 3,
        editable: false,
    },
];

const rows = [
    {id: 1, model: 'HWR Table', description: 'This is the Model-1', created: '2023-05-17', lastUsed: '2024-01-01'},
    {
        id: 2,
        model: 'Classifications',
        description: 'This is the Model-1',
        created: '2023-07-11',
        lastUsed: '2023-10-01'
    },
    {
        id: 3,
        model: 'Directory Extraction',
        description: 'This is the Model-1',
        created: '2021-10-01',
        lastUsed: '2022-05-21'
    }
];

function createData(id, model, description, created, lastUsed) {
    return {id, model, description, created, lastUsed};
}

const rows_2 = [
    createData(1, 'HWR Table', 'This is the Model-1', '2023-05-17', '2024-01-01'),
    createData(2, 'Classifications', 'This is the Model-1', '2023-07-11', '2023-10-01'),
    createData(3, 'Directory Extraction', 'This is the Model-1', '2021-10-01', '2022-05-21')
];
const GridItems = () => {
    const projects1 = [
        // { id: 1, header: 'Loan Application', content: 'Extract data from bank loan application', icon: <img src={EntryIcon} /> },
        // { id: 2, header: 'Handwritten Data', content: 'Extract handwritten data.', icon: <img src={HandIcon} /> },
        // { id: 3, header: 'Handwritten Non-Eng', content: 'Extract & translate various languages.', icon: <img src={TamilIcon} /> },
        // { id: 4, header: 'Census Document', content: 'Custom data extraction', icon: <img src={CensusIcon} /> },
        // { id: 5, header: 'Directory Extraction', content: 'Extract Directory Data', icon: <img src={DirectoryIcon} /> },
        // { id: 6, header: 'NACH Extraction', content: 'Extract NACH Data', icon: <img src={DirectoryIcon} /> },
        // { id: 7, header: 'Handwritten Table', content: 'Extract table data from documents', icon: <img src={TableIcon} /> },
        // { id: 20, header: 'Simple Extraction', content: 'Extract Data', icon: <img src={DirectoryIcon} /> },
        // { id: 21, header: 'Damage Prediction', content: 'Prediction vehicle damage', icon: <img src={DirectoryIcon} /> },
        {id: 25, header: 'Invoices', content: 'Data Extraction From Invoices', icon: <img src={DirectoryIcon}/>},
        {id: 8, header: 'Purchase Order', content: 'Data Extraction From PO', icon: <img src={DirectoryIcon}/>},
        // { id: 9, header: 'KYC Documents', content: 'Extract data from ID documents', icon: <img src={TableIcon} /> },
        // { id: 23, header: 'Pre-Alert IATA', content: 'Extract data from document', icon: <img src={DirectoryIcon} /> },
        // { id: 22, header: 'Pre-Alert DKC Order', content: 'Extract data from document', icon: <img src={DirectoryIcon} /> },
        // { id: 24, header: 'Pre-Alert DKC Type 5', content: 'Extract data from document', icon: <img src={DirectoryIcon} /> },
        {id: 26, header: 'Medical Documents', content: 'Medical Billing Data Extraction', icon: <img src={DirectoryIcon}/>},
        {id: 23, header: 'Airway Bills', content: 'Data Extraction From AWB', icon: <img src={DirectoryIcon}/>},
    ];
    const projects2 = [
        {id: 2, header: 'Handwritten Forms', content: 'HWR Data Extraction.', icon: <img src={HandIcon}/>},
        {id: 7, header: 'Handwritten Tables', content: 'HWR Table Data Extraction', icon: <img src={HandIcon}/>},
    ];
    const projects3 = [
        // { id: 18, header: 'Classifications', content: 'Classify documents', icon: <img src={ClassifyIcon} /> },
        {id: 19, header: 'Lease Documents', content: 'Contextual Data Extraction', icon: <img src={LeaseIcon}/>},
    ];
    const projects4 = [
        {
            id: 21,
            header: 'Vehicle Damage Prediction',
            content: 'Predict Damage From Images',
            icon: <img src={DirectoryIcon}/>
        },
    ];

    return (
        // //, marginLeft:1
        <div>
            <Typography component="div" variant="h8" sx={{fontWeight: 'bold', mt: 3, mb:1}}>
                Recently Used:
            </Typography>
            <Box sx={{height: '20%', width: '90%', paddingBottom: 5}}>
                {/* <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            /> */}
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">Model</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">Last Used</TableCell>
                                <TableCell align="right">Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="right">{row.model}</TableCell>
                                    <TableCell align="right">{row.created}</TableCell>
                                    <TableCell align="right">{row.lastUsed}</TableCell>
                                    <TableCell align="right">{row.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Typography component="div" variant="h8" sx={{fontWeight: 'bold', mt:2, mb:1}}>
                Structured Documents:
            </Typography>
            <Grid container spacing={4} style={{marginBottom: '16px'}} sx={{width: '92%'}}>
                {projects1.map((project) => (
                    <Grid item xs={5} key={project.id}>
                        <BasicCard
                            header={project.header}
                            content={project.content}
                            icon={project.icon}
                            click_element={'upload'}
                            project_id={project.id}
                        />
                    </Grid>
                ))}
            </Grid>
            <Typography component="div" variant="h8" sx={{fontWeight: 'bold', mt:2, mb:1}}>
                Handwritten Documents:
            </Typography>
            <Grid container spacing={4} style={{marginBottom: '16px'}} sx={{width: '92%'}}>
                {projects2.map((project) => (
                    <Grid item xs={5} key={project.id}>
                        <BasicCard
                            header={project.header}
                            content={project.content}
                            icon={project.icon}
                            click_element={'upload'}
                            project_id={project.id}
                        />
                    </Grid>
                ))}
            </Grid>
            <Typography component="div" variant="h8" sx={{fontWeight: 'bold', paddingTop: '2%', mt:2, mb:1}}>
                Unstructured Documents:
            </Typography>
            <Grid container spacing={4} style={{marginBottom: '16px'}} sx={{width: '92%'}}>
                {projects3.map((project) => (
                    <Grid item xs={5} key={project.id}>
                        <BasicCard
                            header={project.header}
                            content={project.content}
                            icon={project.icon}
                            click_element={'upload'}
                            project_id={project.id}
                        />
                    </Grid>
                ))}
            </Grid>
            <Typography component="div" variant="h8" sx={{fontWeight: 'bold', paddingTop: '2%', mt:2, mb:1}}>
                Image Analysis:
            </Typography>
            <Grid container spacing={4} style={{marginBottom: '16px'}} sx={{width: '92%'}}>
                {projects4.map((project) => (
                    <Grid item xs={5} key={project.id}>
                        <BasicCard
                            header={project.header}
                            content={project.content}
                            icon={project.icon}
                            click_element={'upload'}
                            project_id={project.id}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default GridItems;
