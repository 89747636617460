import React, { useState } from 'react';
import { Button, Input } from '@mui/material';
import uploadIcon from './icons/Upload.svg';
import pcIcon from './icons/My-computer-for-loading-files-black.png';
import driveIcon from './icons/Drive-for-loading-files-black.png';
import mailIcon from './icons/Mail-for-loading-files-black.png';
import './DropTypeBox.css';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import ZoomableImage from './zoomomp-med-invoice';
import CommonButton from '../../components/common/CommonButton/CommonButton';
import { red } from '@mui/material/colors';
import { hover } from '@testing-library/user-event/dist/hover';
import { Padding } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const DropTypebox = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [tableJson, setTableJson] = useState([]);
  const [selectedOption, setSelectedOption] = useState('My Device'); // Added state for selected option
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  let [table_, setAllTable] = useState([1, 2, 3]);
  let [header_, setAllTableHeader] = useState([]);
  let [second_table_, setSecondTable] = useState([1, 2, 3]);
  let [second_table_header_, ssetSecondTableHeader] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [extractionType, setExtractionType] = useState('No');
  const [all_context, setAllContext] = useState('');
  const [allOcrData, setAllOcrData] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND

  const [documentType, setDocumentType] = useState('1');
  const [docType, setDocType] = React.useState('');
  const [ID, setID] = React.useState(10);

  const menuItems = [
    { value: 'driving_lisence', label: 'Driving Lisence' },
    { value: 'aadhar', label: 'Aadhar' },
    { value: 'pan_card', label: 'Pan Card' }
  ];

  const handleDocChange = (event) => {
    setDocType(event.target.value);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSetDocumentType = (option) => {
    setDocumentType(option);
    console.log(option);
  };

  const handleFileChange = (event) => {
    setLoading(true); // Set loading state to true when a file is selected
    setSelectedFile(event.target.files[0]);
    setTimeout(() => setLoading(false), 1000); // Simulate loading time and set loading state to false after 3 seconds
  };

  function setDropboxHeader(message) {
    document.getElementById('pop-header').innerText = message;

  };
  const handleUpload = async () => {
    console.log('selectedFile');
    console.log('selectedFile', selectedFile);
    if (!selectedFile) {
      setErrorMessage('Please select a file to upload');
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      setExtractionType(window.globalVar1)
      formData.append('file', selectedFile);
      formData.append('doctype', documentType);
      formData.append('project-id', window.globalVar1);
      console.log(selectedFile)
      console.log(documentType)
      console.log(formData)
      const response = await fetch(backendUrl + '/medical', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          if (response.status === 412) {
            alert("The type is diffrent, Please check the type"); 
          }
          throw new Error('Failed to upload file');
      }
      else{
        const responseData = await response.json();
        console.log(responseData);
        const dataAll = responseData['row'];
        const listall = responseData['column'];
        const all_t_data = responseData['all_table_data'];
        const header = responseData['header'];
        const context_out = responseData['context_out'];
        const sec_table = responseData['all_form_data'];
        const sec_head = responseData['form_header'];
        const ocr_data = responseData['ocr_data'];
        setAllOcrData(ocr_data);
        setAllContext(context_out);
        setSecondTable(sec_table);
        ssetSecondTableHeader(sec_head);
        const all_t_data_list = [];
        responseData['all_table_data'].forEach(line => {
        console.log('line',line);
        all_t_data_list.push(line);
        });
        const all_t_header_list = [];
        header.forEach(line => {
        console.log('line-head',line);
        all_t_header_list.push(line);
        });
        setAllTable(all_t_data_list);
        setAllTableHeader(all_t_header_list);

        const column_data = [];
        listall.forEach(line => {
            const columnBase = {
                field: line.field,
                headerName: line.headerName,
                width: line.width
            };
            column_data.push(columnBase);
        });
        setData(dataAll);
        console.log('sec_table',sec_table);
        console.log('sec_head',sec_head);
        setColumns(column_data);
        setShowModal(false);
        setRedirect(true);
      }
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage('An error occurred during upload. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };


  const handleCloseModal = () => {
    setShowModal(true);
  };


  if (redirect) {
    return (
      <GridWrapper>
        <ZoomableImage imageUrlIp={URL.createObjectURL(selectedFile)}  all_table_data={table_} 
            all_table_header={header_} all_context={all_context} sec_table={second_table_} sec_head={second_table_header_}
            all_ocr_data={allOcrData}/>  
      </GridWrapper>
    );
  } else {
    return (
      <GridWrapper>
        <>
          {/* Render the blur effect if showModal is true */}
          {showModal && <div className="blur-background" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 999 }} onClick={handleCloseModal}></div>}

          {/* Render the modal if showModal is true */}
          {showModal && (
            <div className="modal">
              <div className='popup-header'>
                <p id='pop-header'>Add new files</p>
              </div>
              <div className='popup-content'>
                <div className='popup-leftmenu'>
                  <p className={selectedOption === 'My Device' ? 'selected' : ''} onClick={() => handleOptionSelect('My Device')}>
                    <img src={pcIcon} alt="pc" /> My Device
                  </p>
                  <p className={selectedOption === 'Google Drive' ? 'selected' : ''} onClick={() => handleOptionSelect('Google Drive')}>
                    <img src={driveIcon} alt="Gd" /> Google Drive
                  </p>
                  <p className={selectedOption === 'Email' ? 'selected' : ''} onClick={() => handleOptionSelect('Email')}>
                    <img src={mailIcon} alt="mail" /> Email
                  </p>
                </div>
                <div className='popup-content'>
                  <div className='popup-sub-leftmenu'>
                    <p className={documentType === 'Type 1' ? 'selected' : ''} onClick={() => handleSetDocumentType('Type 1')}>
                       <small>Type 1</small>
                    </p>
                    <p className={documentType === 'Type 2' ? 'selected' : ''} onClick={() => handleSetDocumentType('Type 2')}>
                       <small> Type 2 </small>
                    </p>
                  </div>
                </div>
                <div
                  className={`dropzone ${dragging ? 'dragging' : ''}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={(e) => e.preventDefault()}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <Input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-image"
                  />
                  {loading && <div className="loading-spinner"></div>} {/* Show loading spinner when loading state is true */}
                  {!selectedFile && !loading && (
                    <>
                      <div className='upload-container'>
                        <img src={uploadIcon} alt="Upload" onClick={() => document.getElementById('upload-image').click()} />
                        <p>Drag and drop or <span className='clickme' onClick={() => document.getElementById('upload-image').click()}>click here</span> to add files</p>
                      </div>
                    </>
                  )}
                  {selectedFile && !loading && (
                    <>
                      <div className='afterSelection'>
                        <img src={URL.createObjectURL(selectedFile)} alt="Selected" className="selected-image" />
                        {/* <Button variant="contained" onClick={() => document.getElementById('upload-image').click()} style={{ position: 'absolute', bottom: 20, right: 100 }}>Select file</Button> */}
                        {/* <Button variant="contained" onClick={handleUpload} style={{ position: 'absolute', bottom: 20, right: 20 }}>Done</Button> */}
                        <div onClick={() => document.getElementById('upload-image').click()}>
                          <CommonButton
                            variant="outlined"
                            sx={{
                              color: '#000000',
                              padding: '5px 40px ',
                              size: '10px',
                              border: '1px solid #674f87',
                              borderRadius: '10px',
                              position: 'absolute', bottom: 20, right: 130,
                              '&:hover': {
                                background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                                border: '1px solid #674f87',
                                color: '#ffffff'
                              }
                            }}


                          >Select file
                          </CommonButton>
                        </div >
                        <div onClick={handleUpload}>
                          <CommonButton variant="outlined"
                            sx={{
                              background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                              color: '#ffffff',
                              border: '1px solid #674f87',
                              padding: '5px 30px ',
                              size: '10px',
                              borderRadius: '10px',
                              position: 'absolute', bottom: 20, right: 20,
                              '&:hover': {
                                background: 'linear-gradient(to right, #674f87 1%, #ef798a 200%)',
                                border: '1px solid #674f87',
                                color: '#ffffff'
                              }
                            }}

                          >Done
                          </CommonButton>
                        </div>
                        {ID === 1 && (
                          <Box sx={{ position: 'absolute', bottom: 20, right: 320 }}>
                            <FormControl sx={{ width: '200px' }}>
                              <Select
                                id="demo-simple-select"
                                value={docType}
                                onChange={handleDocChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                  padding: '6px 5px',
                                  fontSize: '15px',
                                  border: '1px solid #674f87',
                                  borderRadius: '10px',
                                  '&:hover': {
                                    background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                                    border: '1px solid #674f87',
                                    color: '#ffffff'
                                  }
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 132, // Adjust as needed based on item height and number of items
                                      width: '20ch',
                                      marginTop: '8px'
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="">SELECT FILE TYPE</MenuItem>
                                {menuItems.map((item, index) => (
                                  <MenuItem
                                    key={item.value}
                                    value={item.value}
                                    sx={{
                                      '&:hover': {
                                        background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                                        color: '#ffffff'
                                      }
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      </GridWrapper>
    );
  }
};

export default DropTypebox;
