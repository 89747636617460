import React from 'react';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';

import Dropbox from './DropBox'
import DropBoxInvoice from './DropBox-invoice'
import DropTypebox from './DropTypeBox'
// const UploadDoc = () => {
//         return (
//             <GridWrapper>
//                <Dropbox/>
//             </GridWrapper>
//         );
//
// };
//
// export default UploadDoc;

const UploadDoc = () => {
    const project_id = window.globalVar1;

    const renderUploadComponent = () => {
        try {
            if (project_id === 26) {
                return <DropTypebox/>;
            }
            else if (project_id === 25) {
                return <DropBoxInvoice/>;
            }
            else{
                return <Dropbox />;
            }
        } catch (error) {
            console.error('Error rendering upload component:', error);
            return <div>Error loading upload component. Please try again later.</div>;
        }
    };

    return (
        <GridWrapper>
            {renderUploadComponent()}
        </GridWrapper>
    );
};

export default UploadDoc;
